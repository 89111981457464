import { UnreachableCaseError } from '@orus.eu/error'
import { Button, useAsyncCallback, useEnqueueToast } from '@orus.eu/pharaoh'
import { isSuccess } from '@orus.eu/result'
import { sleep } from '@orus.eu/sleep'
import { useState, type ReactText } from 'react'
import { trpc } from '../../../client'

type PayInvoiceButtonProps = {
  invoiceId: string
  children?: ReactText
  confirmText: string
}

/**
 * This button attempts the payment of an invoice when clicked. After it displays the result and cause the
 * page to reload, to make sure all information on the page is up-to-date.
 * @param invoiceId
 * @param children
 * @param confirmText
 * @constructor
 */
export function PayInvoiceButton({ invoiceId, children, confirmText }: PayInvoiceButtonProps): JSX.Element {
  const [state, setState] = useState<'idle' | 'working'>('idle')
  const { enqueueToast } = useEnqueueToast()

  const attemptPayment = useAsyncCallback(async () => {
    setState('working')
    const attemptResult = await trpc.invoices.attemptInvoicePayment.mutate(invoiceId)

    if (isSuccess(attemptResult)) {
      enqueueToast(confirmText, { variant: 'success' })
    } else {
      const { type } = attemptResult.problem
      switch (type) {
        case 'payment-already-in-progress':
          enqueueToast('Un paiement est déjà en cours pour cette facture', { variant: 'warning' })
          break
        case 'payment-not-allowed':
          enqueueToast(`Le paiement de cette facture n'est pas possible : ${attemptResult.problem.reason}`, {
            variant: 'danger',
          })
          break
        case 'payment-failed':
          enqueueToast('Le paiement a échoué', { variant: 'danger' })
          break
        default:
          throw new UnreachableCaseError(type)
      }
    }

    await sleep(2_000)

    document.location.reload()
  }, [confirmText, invoiceId, enqueueToast])

  return (
    <Button
      size="small"
      variant="primary"
      disabled={state !== 'idle'}
      onClick={attemptPayment}
      isLoading={state === 'working'}
    >
      {children}
    </Button>
  )
}
